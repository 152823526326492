/* bootstrap overwrite */
.image-text-component.standard-variant .image-col .caption, .image-text-component.bucketList-variant .image-col .caption {
  color: #657785;
}
.image-text-component.standard-variant .image-col .copyright, .image-text-component.bucketList-variant .image-col .copyright {
  color: #c9d1e3;
}
.image-text-component h1::after,
.image-text-component .h1::after {
  mask: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgzIiBoZWlnaHQ9IjgiIHZpZXdCb3g9IjAgMCAxODMgOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIgNS41MTk0NEM0Ny41IDAuODI2OTA0IDk3IDAuODI2OTM5IDE4MSA1LjUxOTU1IiBzdHJva2U9IiMyNzJBNDUiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
  mask-repeat: no-repeat;
  filter: none !important;
  background-image: none !important;
  background-color: #d3ddf2;
}
@media (min-width: 768px) {
  .image-text-component h1::after,
.image-text-component .h1::after {
    mask: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzMwIDExIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMiA4LjE2OEM4Ni4xNTIzIDEuOTYzMDMgMjAzLjExNiAtMi4xOTY5OSAzMjggOSIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSI0IiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
    mask-repeat: no-repeat;
  }
}
.bg-dark .image-text-component h1::after,
.bg-dark .image-text-component .h1::after {
  filter: none;
  background-color: #bcf0e8;
}
.image-text-component.bucketList-variant h1::after,
.image-text-component.bucketList-variant .h1::after {
  display: none;
}