@import 'webresources-sources/scss/imports';

.image-text-component {
  &.standard-variant,
  &.bucketList-variant {
    .image-col {
      .caption {
        color: $color-gray-600;
      }

      .copyright {
        color: $color-gray-500;
      }
    }
  }

  h1,
  .h1 {
    &::after {
      mask: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgzIiBoZWlnaHQ9IjgiIHZpZXdCb3g9IjAgMCAxODMgOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIgNS41MTk0NEM0Ny41IDAuODI2OTA0IDk3IDAuODI2OTM5IDE4MSA1LjUxOTU1IiBzdHJva2U9IiMyNzJBNDUiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==');
      mask-repeat: no-repeat;
      filter: none !important;
      background-image: none !important;
      background-color: $color-bluegray-500;

      @include media-breakpoint-up(md) {
        mask: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzMwIDExIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMiA4LjE2OEM4Ni4xNTIzIDEuOTYzMDMgMjAzLjExNiAtMi4xOTY5OSAzMjggOSIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSI0IiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K');
        mask-repeat: no-repeat;
      }

      .bg-dark & {
        filter: none;
        background-color: $color-darkgreen-200;
      }
    }
  }

  &.bucketList-variant {
    h1,
    .h1 {
      &::after {
        display: none;
      }
    }
  }
}
